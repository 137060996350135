import React, { useState } from "react";

import style from "./Stocks.module.css";
import { useHorizontalScroll } from "../Booking/useSideScroll";

const all_images = [
  "https://justplayspb.ru/content/wp-images/all_stocks/all_stock_1.webp",
  "https://justplayspb.ru/content/wp-images/all_stocks/all_stock_2.webp",
  "https://justplayspb.ru/content/wp-images/all_stocks/all_stock_3.webp",
  "https://justplayspb.ru/content/wp-images/all_stocks/all_stock_4.webp",
  "https://justplayspb.ru/content/wp-images/all_stocks/all_stock_5.webp",
];

const org_images = [
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_8.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_9.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_2.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_4.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_3.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_1.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_5.webp",

  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_6.webp",
  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_7.webp",

  "https://justplayspb.ru/content/wp-images/org_stocks/org_stocks_10.webp",
];

export default function Stocks() {
  const [choiceType, setChoiceType] = useState("ALL");

  const AllStocks = () => {
    {
      return all_images.map((src) => <img src={src} alt="" />);
    }
  };
  const OrgStocks = () => {
    {
      return org_images.map((src) => <img src={src} alt="" />);
    }
  };

  const scrollRef = useHorizontalScroll(8);
  return (
    <section className={style.sectionWrapper}>
      <div className={style.contentWrapper}>
        <h3 style={{ color: "#ffaa00" }}>АКЦИИ И СКИДКИ</h3>
        <div className={style.stocksButtons}>
          <div
            className={style.stockButton}
            style={{
              border: choiceType === "ALL" ? "1px solid #ffaa00" : "none",
            }}
            onClick={() => {
              setChoiceType("ALL");
            }}
          >
            <h3>ВСЕМ</h3>
          </div>
          <div
            className={style.stockButton}
            style={{
              border: choiceType === "ORG" ? "1px solid #ffaa00" : "none",
            }}
            onClick={() => {
              setChoiceType("ORG");
            }}
          >
            <h3>ОРГАНИЗАЦИЯМ</h3>
          </div>
        </div>
        <div className={style.stockWrapper} ref={scrollRef}>
          {choiceType === "ALL" ? <AllStocks /> : <OrgStocks />}
        </div>
      </div>
    </section>
  );
}
