import React from "react";

import style from "./Header.module.css";

const logoIMG = "https://justplayspb.ru/content/wp-images/main/logo.svg";

export default function Header() {
  return (
    <header>
      <div className={style.headerSectionWrapper}>
        <div className={style.logoWrapper}>
          <img src={logoIMG} alt="" />
        </div>
      </div>
    </header>
  );
}
