import React from "react";

import style from "./VideoTrailer.module.css";

const videoSRC = "https://justplayspb.ru/content/justplay-video.mp4";

export default function VideoTrailer() {
  return (
    <section className={style.videoSectionWrapper}>
      <div className={style.videoWrapper}>
        <video playsInline loop autoPlay muted>
          <source src={videoSRC} type="video/mp4" />
        </video>
      </div>
    </section>
  );
}
