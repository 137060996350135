import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";

import style from "./Booking.module.css";
import { useHorizontalScroll } from "./useSideScroll";

const standartPrices = [
  {
    time: "1Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "50" },
      { day: "ПТ-ВС", total: "80" },
    ],
    desc: "АКТИВАЦИЯ С 08:00 ДО 13:00",
  },
  {
    time: "1Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "140" },
      { day: "ПТ-ВС", total: "160" },
    ],
  },
  {
    time: "3Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "350" },
      { day: "ПТ-ВС", total: "420" },
    ],
  },
  {
    time: "5Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "570" },
      { day: "ПТ-ВС", total: "620" },
    ],
  },
  {
    time: "12Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "900" },
      { day: "ПТ-ВС", total: "1000" },
    ],
  },
  {
    time: "24Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "1300" },
      { day: "ПТ-ВС", total: "1400" },
    ],
  },
  {
    time: "УТРО",
    prcs: [
      { day: "ПН-ЧТ", total: "270" },
      { day: "ПТ-ВС", total: "370" },
    ],
    desc: "7Ч АКТИВАЦИЯ С 08:00 ДО 10:00",
  },
  {
    time: "ДЕНЬ",
    prcs: [
      { day: "ПН-ЧТ", total: "450" },
      { day: "ПТ-ВС", total: "550" },
    ],
    desc: "7Ч АКТИВАЦИЯ С 10:00 ДО 14:00",
  },
  {
    time: "НОЧЬ",
    prcs: [
      { day: "ПН-ЧТ", total: "650" },
      { day: "ПТ-ВС", total: "750" },
    ],
    desc: "АКТИВАЦИЯ С 22:00 ДО 08:00",
  },
];

const vipPrices = [
  {
    time: "1Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "140" },
      { day: "ПТ-ВС", total: "160" },
    ],
  },
  {
    time: "3Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "350" },
      { day: "ПТ-ВС", total: "420" },
    ],
  },
  {
    time: "5Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "570" },
      { day: "ПТ-ВС", total: "620" },
    ],
  },
  {
    time: "12Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "900" },
      { day: "ПТ-ВС", total: "1000" },
    ],
  },
  {
    time: "24Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "1300" },
      { day: "ПТ-ВС", total: "1400" },
    ],
  },
  {
    time: "УТРО",
    prcs: [
      { day: "ПН-ЧТ", total: "270" },
      { day: "ПТ-ВС", total: "370" },
    ],
    desc: "7Ч АКТИВАЦИЯ С 08:00 ДО 10:00",
  },
  {
    time: "ДЕНЬ",
    prcs: [
      { day: "ПН-ЧТ", total: "450" },
      { day: "ПТ-ВС", total: "550" },
    ],
    desc: "7Ч АКТИВАЦИЯ С 10:00 ДО 14:00",
  },
  {
    time: "НОЧЬ",
    prcs: [
      { day: "ПН-ЧТ", total: "650" },
      { day: "ПТ-ВС", total: "750" },
    ],
    desc: "АКТИВАЦИЯ С 22:00 ДО 08:00",
  },
];

const tvPrices = [
  {
    time: "1Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "280" },
      { day: "ПТ-ВС", total: "350" },
    ],
    desc: "ㅤ",
  },
  {
    time: "3Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "650" },
      { day: "ПТ-ВС", total: "850" },
    ],
  },
  {
    time: "5Ч",
    prcs: [
      { day: "ПН-ЧТ", total: "1000" },
      { day: "ПТ-ВС", total: "1250" },
    ],
  },
];

export default function Booking() {
  const [choiceType, setChoiceType] = useState("STANDART");
  const [activeCard, setActiveCard] = useState(null);
  const [choice, setChoice] = useState("");
  const [status, setStatus] = useState("");
  const [typeDate, setTypeDate] = useState(false);

  // Создаём состояния для наших инпутов.
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [count, setCount] = useState(null);
  const [date, setDate] = useState(`${new Date().toISOString().substr(0, 16)}`);

  // Создаём функции для изменения состояния имени и валидации имени.
  const changeName = (e) => {
    setName(e.target.value);
  };

  // Функци для изменения телефона и валидации телефона.
  const changeHandlerDate = (e) => {
    setDate(e.target.value);
  };
  // Функци для изменения телефона и валидации телефона.
  const changeHandlerCount = (e) => {
    setCount(e.target.value);
  };

  // Валидации всей формы и кнопки отправки
  const [formValid, setFormValid] = useState(false);
  useEffect(() => {
    console.log(name, phone, date, count, choice);
    if (!name || !phone || !date || !count || !choice) {
      setFormValid(false);
    } else {
      setFormValid(true);
      setStatus("");
    }
  }, [name, phone, date, count, choice]);

  // Функция для отправки формы
  const submitData = (e) => {
    e.preventDefault();
    setStatus("Отправка формы");
    fetch("http://188-93-211-116.cloudvps.regruhosting.ru:5000/telegram", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, phone, price: choice, count, date }),
    })
      .then((response) => response.json())
      .then((result) => setStatus("УСПЕШНО!"))
      .catch((error) => setStatus("Ошибка на стороне сервера!"));
  };

  const changeActiveCard = (stroke, card) => {
    setChoice(stroke);
    setActiveCard(card);
  };

  const Price = ({ cls, type, props }) => {
    return (
      <div className={style.priceColumn} key={cls + type + props.time}>
        <div
          className={style.priceWrapper}
          key={
            cls +
            " " +
            type +
            " " +
            props.time +
            " " +
            props.prcs[0].day +
            " " +
            props.prcs[0].total
          }
          onClick={() =>
            changeActiveCard(
              cls + " " + type + " " + props.time,
              cls +
                " " +
                type +
                " " +
                props.time +
                " " +
                props.prcs[0].day +
                " " +
                props.prcs[0].total
            )
          }
          style={{
            borderColor:
              cls +
                " " +
                type +
                " " +
                props.time +
                " " +
                props.prcs[0].day +
                " " +
                props.prcs[0].total ===
              activeCard
                ? "#ffaa00"
                : "#000",
          }}
        >
          <div className={style.headerText}>
            <h3>{cls}</h3>
            <p style={{ color: "#ffaa00" }}>{type}</p>
          </div>
          <div className={style.mainPriceContent}>
            <h2>{props.time}</h2>
            <div className={style.columnWrapper}>
              <p>{props.prcs[0].day}</p>
              <p>{props.prcs[1].day}</p>
            </div>
            <div className={style.columnWrapper}>
              <p>{props.prcs[0].total}Р</p>
              <p>{props.prcs[1].total}Р</p>
            </div>
          </div>
          {props.desc ? (
            <div className={style.footerPriceContent}>
              <p>{props.desc}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  const scrollRef = useHorizontalScroll(5);
  return (
    <section className={style.sectionWraper}>
      <div className={style.contentWrapper}>
        {/* ценники и выбор тарифа */}
        <div className={style.pWrapper}>
          <h3 style={{ color: "#ffaa00" }}>Забронируй прямо сейчас!</h3>
          <p>• выбери зал (standart, vip или tv)</p>
          <p>• выбери подходящий тариф</p>
          <p>
            • заполни форму (имя, телефон, дата и время посещения, кол-во пк)
          </p>
          <p style={{ color: "#ff0000" }}>
            Администратор может перезвонить для уточнения информации!
          </p>
        </div>
        <div className={style.mainWrapper}>
          <div className={style.pricesWrapper}>
            <div className={style.priceButtons}>
              <div
                className={style.priceButton}
                style={{
                  border:
                    choiceType === "STANDART" ? "1px solid #ffaa00" : "none",
                }}
                onClick={() => {
                  setChoiceType("STANDART");
                }}
              >
                <h3>STANDART</h3>
              </div>
              <div
                className={style.priceButton}
                style={{
                  border: choiceType === "VIP" ? "1px solid #ffaa00" : "none",
                }}
                onClick={() => {
                  setChoiceType("VIP");
                }}
              >
                <h3>VIP</h3>
              </div>
              <div
                className={style.priceButton}
                style={{
                  border: choiceType === "TV" ? "1px solid #ffaa00" : "none",
                }}
                onClick={() => {
                  setChoiceType("TV");
                }}
              >
                <h3>TV</h3>
              </div>
            </div>
            <div className={style.priceButtons}>
              {choiceType === "STANDART" ? (
                <p>• I5 11400f • RTX3060TI • DDR4 16 GB • ASUS 24.5' 280HZ</p>
              ) : choiceType === "VIP" ? (
                <p>• I7-12700F • RTX3070TI • DDR4 16 GB • ASUS 24.5' 280HZ</p>
              ) : (
                <p>• PLAYSTATION 5</p>
              )}
            </div>
            <div className={style.pricesContent} ref={scrollRef}>
              {choiceType === "STANDART"
                ? standartPrices.map((value) => (
                    <Price cls="STANDART" type="PC" props={value} />
                  ))
                : choiceType === "VIP"
                ? vipPrices.map((value) => (
                    <Price cls="VIP" type="PC" props={value} />
                  ))
                : tvPrices.map((value) => (
                    <Price cls="VIP" type="TV" props={value} />
                  ))}
            </div>
          </div>
          {/* поле для ввода информации */}
          <div className={style.bookingWrapper}>
            <h3>ФОРМА ДЛЯ БРОНИРОВАНИЯ</h3>
            <input
              style={{ marginTop: "1em", borderRadius: "8px 8px 0px 0px" }}
              placeholder="имя"
              type="text"
              value={name}
              name="name"
              onChange={changeName}
            />
            <Input
              country="RU"
              placeholder="номер телефона (без кода страны)"
              value={phone}
              onChange={setPhone}
            />

            <div className={style.inputWrapper}>
              <input
                style={{ borderRadius: "0px 0px 0px 8px" }}
                type="datetime-local"
                value={date}
                name="date"
                onChange={changeHandlerDate}
              />
              <input
                style={{ borderRadius: "0px 0px 8px 0px" }}
                placeholder="количество пк"
                type="number"
                value={count}
                name="count"
                onChange={changeHandlerCount}
              />
            </div>
            <p style={{ color: "#ffaa00" }}>
              {status.length > 0 ? status : ""}
            </p>
            <button
              style={{ color: "#fff", borderRadius: "8px" }}
              disabled={!formValid}
              type="submit"
              onClick={submitData}
              className={style.bookingButton}
            >
              <h3>ЗАБРОНИРОВАТЬ</h3>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
