import React from "react";

import style from "./Location.module.css";

const images = {
  justplay: "https://justplayspb.ru/content/wp-images/main/location.webp",
  vk: "https://justplayspb.ru/content/wp-images/icons/vk.webp",
  tg: "https://justplayspb.ru/content/wp-images/icons/telegram.webp",
};

export default function Location() {
  return (
    <section className={style.sectionWraper}>
      <div className={style.contentWrapper}>
        {/* <div className={style.testText}>
          <h1>ГДЕ</h1>
          <h1>НАС</h1>
          <h1>НАЙТИ?</h1>
          <p>просто наведи на карту</p>
        </div> */}
        {/* <iframe
          className={style.map}
          title="map-location"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A31c192dc16ad0b491f3dd240926a9ff316e35e0573a6affc4a929ce91da67181&amp;source=constructor&amp;scroll=false"
          width="100%"
        /> */}
        <div className={style.mapWrapper}>
          <div className={style.mapText}>
            <h1>ГДЕ</h1>
            <h1>НАС</h1>
            <h1>НАЙТИ?</h1>
            <p>просто наведи на карту</p>
          </div>
          <iframe
            className={style.map}
            title="map-location"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A31c192dc16ad0b491f3dd240926a9ff316e35e0573a6affc4a929ce91da67181&amp;source=constructor&amp;scroll=false"
            width="100%"
          />
        </div>
        <div className={style.infoWrapper}>
          <div className={style.infoImageWrapper}>
            <img src={images.justplay} alt="" />
          </div>
          <div className={style.infoTextWrapper}>
            <h3>г. Санкт-Петербург, ул. Индустриальный проспект, 17 К1Б</h3>
            <p>• 40 геймерских ПК • 2 VIP-комнаты • Playstation 5</p>
            <p>• проведение турниров</p>
            <p>• организация мероприятий</p>
            <div className={style.infoRefsWrapper}>
              <a href="tel:+79934821670">
                <h4>+7 (993) 482-16-70</h4>
              </a>
              <div className={style.infoSocialWrapper}>
                <a
                  href="https://vk.com/arena_justplay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>VK</h4>
                </a>
                <a
                  href="https://t.me/arena_justplay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>TG</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
