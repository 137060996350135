import React, { useEffect, useState } from "react";
import Loader from "./Loader/Loader";
import Header from "./Header/Header";
import VideoTrailer from "./VideoTrailer/VideoTrailer";
import Location from "./Location/Location";
import Booking from "./Booking/Booking";
import Stocks from "./Stocks/Stocks";
import Footer from "./Footer/Footer";
import Gallery from "./Gallery/Gallery";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   if (isLoading) {
  //     setTimeout(() => {
  //       setIsLoading(true);
  //     }, 40000);
  //   }
  // }, [isLoading]);
  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4500);
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Header />
      <VideoTrailer />
      <Location />
      <Booking />
      <Stocks />
      <Gallery />
      <Footer />
    </div>
  );
}
