import React from "react";

import style from "./Footer.module.css";

export default function Footer() {
  return (
    <footer>
      <div className={style.footerSectionWrapper}></div>
    </footer>
  );
}
