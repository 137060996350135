import React from "react";

import style from "./Gallery.module.css";

export default function Gallery() {
  return (
    <section className={style.sectionWrapper}>
      <div className={style.contentWrapper}>
        <a
          href="https://vk.com/albums-217508984"
          target="_blank"
          rel="noreferrer"
        >
          <h3>ЕЩЕ БОЛЬШЕ ФОТОГРАФИЙ В НАШИх АЛЬБОМАх ВКОНТАКТЕ!</h3>
        </a>
      </div>
    </section>
  );
}
