import React from "react";

import style from "./Loader.module.css";

export default function Loader() {
  return (
    <div className={style.wrapper}>
      <div className={style.textWrapper}>
        <h2 className="hero glitch layers" data-text="JUSTPLAY">
          <span>JUSTPLAY</span>
        </h2>
      </div>
      <div className={style.loaderWrapper}>
        <span className={style.loader}></span>
      </div>

      <div className={style.imageWrapper}></div>
    </div>
  );
}
